<template>
  <div class="center">
    <!--列表label-->
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t(`cip.plat.wfOps.proxy.title.indexHeadTitle`)"
                 @head-add="rowSave"
                 @head-delete="handleDelete"></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"></grid-head-layout>
    <grid-layout ref="gridLayOut"
                 :table-options="option"
                 :table-data="data"
                 :table-loading="loading"
                 :data-total="page.total"
                 :grid-row-btn="gridRowBtn"
                 :page="page"
                 @page-current-change="onLoad"
                 @page-size-change="onLoad"
                 @page-refresh-change="onLoad"
                 @row-del="rowDel"
                 @row-edit="rowEdit"></grid-layout>
    <!--    <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               :permission="permissionList"
               :before-open="beforeOpen"
               v-model="form"
               ref="crud"
               @row-update="rowUpdate"
               @row-save="rowSave"
               @row-del="rowDel"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionChange"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="refreshChange"
               @on-load="onLoad">
      <template #userId="{row}">
        {{getUserName(row.userId)}}
      </template>
      <template #proxyUserId="{row}">
        {{getUserName(row.proxyUserId)}}
      </template>
      <template #time="{row}">
        <span v-if="row.type == 1">永久</span>
        <span v-else>{{row.startTime}} ~ {{row.endTime}}</span>
      </template>
      <template #processDefKey="{row}">
        <span v-if="row.processDefKey == 'WF_ALL_PROCESS'">全部流程</span>
        <span v-else>{{row.processDefKey}}</span>
      </template>
      <template slot="menuLeft">
        <el-button type="danger"
                   size="mini"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.wf_ops_proxy_delete"
                   @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>-->
  </div>
</template>

<script>
import { getList, remove } from "@/api/plugin/workflow/proxy"
import { getList as userList } from '@/api/system/user'
import { mapGetters } from "vuex"
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

export default {
  components: { GridLayout, HeadLayout },
  data () {
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        size: 'mini',
        height: 'auto',
        calcHeight: 30,
        tip: false,
        border: true,
        selection: true,
        dialogType: 'drawer',
        align: 'center',
        searchMenuSpan: 6,
        searchSize: 'mini',
        searchIndex: 3,
        searchIcon: true,
        column: [
          {
            label: this.$t('cip.plat.wfOps.proxy.field.client'),
            prop: "userName",
            component: 'wf-user-select',
            params: {
              checkType: 'radio',
            },
            overHidden: true,
            align:'center',
            width: 200,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.client'),
              trigger: "change"
            }],
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.agent'),
            prop: "proxyUserName",
            component: 'wf-user-select',
            overHidden: true,
            align:'center',
            width: 200,
            params: {
              checkType: 'radio',
            },
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.agent'),
              trigger: "change"
            }],
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.process'),
            prop: 'process',
            type: 'radio',
            overHidden: true,
            align:'left',
            dicData: [{
              label: this.$t('cip.plat.wfOps.proxy.field.total'),
              value: '1'
            }, {
              label: this.$t('cip.plat.wfOps.proxy.field.appoint'),
              value: '2'
            }],
            hide: true,
            change: ({ value }) => {
              const processDefKey = this.findObject(this.option.column, 'processDefKey')
              if (value == '2') {
                processDefKey.display = true
                if (this.form.processDefKey == 'WF_ALL_PROCESS') this.form.processDefKey = ''
              } else {
                processDefKey.display = false
                this.form.processDefKey = 'WF_ALL_PROCESS'
              }
            },
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.Process'),
              trigger: "blur"
            }],
            value: '1'
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.specifyTheProcess'),
            prop: "processDefKey",
            dataType: 'string',
            type: 'tree',
            align:'left',
            dicUrl: '/api/sinoma-workflow/design/deployment/list?size=-1',
            dicFormatter: (res) => {
              const data = res.data.records
              if (data && data.length > 0) {
                return [{
                  name: this.$t('cip.plat.wfOps.proxy.field.total'),
                  key: 'all',
                  children: data
                }]
              }
              return data
            },
            defaultExpandAll: true,
            leafOnly: true,
            props: {
              label: 'name',
              value: 'key',
            },
            multiple: false,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.Process'),
              trigger: "blur"
            }],
            span: 24,
            overHidden: true,
            search: true,
            display: true
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.type'),
            prop: "type",
            align:'center',
            type: 'select',
            // dicData: [{
            //   label: this.$t('cip.plat.wfOps.proxy.field.permanent'),
            //   value: '1'
            // }, {
            //   label: this.$t('cip.plat.wfOps.proxy.field.timing'),
            //   value: '2'
            // }],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_timing",
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.type'),
              trigger: "blur"
            }],
            change: ({ value }) => {
              const time = this.findObject(this.option.column, 'time')
              if (value == '2') {
                time.display = true
              } else {
                time.display = false
              }
            },
            span: 24,
            search: true,
            width: 165,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.startTime'),
            prop: "startTime",
            align:'center',
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            width: 165,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.effectiveTime'),
              trigger: "blur"
            }],
            display: false,
            span: 24,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.endTime'),
            prop: "endTime",
            align:'center',
            type: 'datetime',
            width: 165,
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.effectiveTime'),
              trigger: "blur"
            }],
            display: false,
            span: 24,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.status'),
            prop: 'status',
            align:'center',
            type: 'select',
            // dicData: [{
            //   label: this.$t('cip.plat.wfOps.proxy.field.available'),
            //   value: 1
            // }, {
            //   label: this.$t('cip.plat.wfOps.proxy.field.forbidden'),
            //   value: 2
            // }],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_available",
            value: 1,
            search: true,
            span: 24,
            width: 100
          }
        ]
      },
      data: [],
      userList: []
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.wf_ops_proxy_add, false),
        viewBtn: this.vaildData(this.permission.wf_ops_proxy_view, false),
        delBtn: this.vaildData(this.permission.wf_ops_proxy_delete, false),
        editBtn: this.vaildData(this.permission.wf_ops_proxy_edit, false)
      };
    },
    ids () {
      let ids = [];
      let selectionList = this.$refs.gridLayOut.selectionList
      selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns () {
      return [
        {
          label: this.$t('cip.plat.wfOps.proxy.field.client'),
          prop: "userName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.wfOps.proxy.field.client'),
        },
      ]
    },
    headBtnOptions () {
      let result = [];
      //新增按钮
      if (this.permission.wf_ops_proxy_add) {
        result.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_ops_proxy_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-delete",
          type: "danger",
          icon: "el-icon-delete",
        });
      }
      return result;
    },
    // 行按钮添加 add by steve
    gridRowBtn () {
      let result = [];
      //新增按钮
      if (this.permission.wf_ops_proxy_edit) {
        result.push({
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.wf_ops_proxy_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-del",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      return result;
      //新增按钮
    },
  },
  mounted () {
    userList({current:1,size:-1}).then(res => {
      this.userList = res.data.data.records
    })
    this.onLoad(this.page, {});
  },
  methods: {
    // 列表查询 add by steve
    gridHeadSearch (searchForm) {
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty (searchForm) {
      this.onLoad(this.page, searchForm);
    },
    getUserName (id) {
      const user = this.userList.find(u => u.id == id)
      if (user) return user.realName
      return ''
    },
    rowSave () {
      this.$router.push({
        path: '/plugin/workflow/ops/proxyEdit',
        query: {
          type: 'add',
        }
      })
    },
    rowEdit (row) {
      let data = encodeURIComponent(JSON.stringify(row))
      this.$router.push({
        path: '/plugin/workflow/ops/proxyEdit',
        query: {
          type: 'edit',
          data: data
        }
      })
    },
    rowDel (row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.gridHeadSearch({})
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    handleDelete () {
      let selectionList = this.$refs.gridLayOut.selectionList
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
          });
        });
    },
    onLoad (page, params = {}) {
      this.page = page
      this.loading = true;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.$refs.gridHeadLayout.searchForm)).then(res => {
        const data = res.data.data;
        this.$nextTick(()=>{
          this.$refs.gridLayOut.page.total = data.total;
        })
        this.data = data.records;
        this.loading = false;
      });
    }
  }
};
</script>

<style>
</style>
